<template>
  <div>
    <div class="models" @click="close"></div>
    <div class="dialogWarp">
      <h1>
        意见反馈
        <span @click="close">
          <img src="../assets/images/baseline-close.png" alt="" />
        </span>
      </h1>
      <div class="container">
        <form action="">
          <label for="name">姓名</label>
          <input
            v-model="name"
            type="text"
            name="name"
            maxlength="20"
            aria-placeholder="请输入您的姓名"
          />
          <label for="connect">联系方式</label>
          <input
            v-model="connect"
            type="number"
            name="contactInfo"
            maxlength="20"
            aria-placeholder="请输入您的联系方式"
          />
          <label for="content">内容</label>
          <textarea
            v-model="content"
            name="message"
            maxlength="1000"
          ></textarea>
        </form>
      </div>
      <div class="dialogFooter">
        <button @click="close">取消</button>&nbsp;
        <button class="primary" @click="save">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "ZzjFeedback",
  data() {
    return {
      name: "",
      connect: "",
      content: "",
    };
  },
  methods: {
    save() {
      if (!this.name) {
        alert("名字不能为空");
        return false;
      }
      if (!this.connect) {
        alert("联系方式不能为空");
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(this.connect)) {
        alert("联系方式输入错误");
        return false;
      }
      if (!this.content) {
        alert("内容不能为空");
        return false;
      }
      const data = {
        name: this.name,
        contactInfo: this.connect,
        message: this.content,
        captcha: "spdkit",
      };
      $.ajax({
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        headers: {
          version: 1,
        },
        url: "http://www.spiderbim.devp/identity/api/v1/Contact/Add",
        data: JSON.stringify(data),
        success: function (data) {
          if (data.success) {
            this.close();
            alert("提交意见反馈成功");
          }
        },
        error: function () {
          alert("提交失败，请重新尝试");
        },
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.models {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.dialogWarp {
  width: 528px;
  height: 468px;
  box-shadow: 0 0 5px 0;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background-color: #ffffff;
}
.dialogWarp .container {
  padding: 0 20px;
}
.dialogWarp label {
  cursor: pointer;
  font-size: 14px;
  display: block;
  color: #333333;
  line-height: 40px;
}
.dialogWarp h1 {
  position: relative;
  font-size: 16px;
  color: #333333;
  height: 50px;
  border-bottom: 1px solid #ddd;
  line-height: 50px;
  padding-left: 20px;
  font-weight: normal;
}
.dialogWarp h1 span {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.dialogWarp input {
  width: 100%;
  border: 1px solid #dddddd;
  height: 40px;
  border-radius: 4px;
}
.dialogWarp textarea {
  width: 100%;
  border: 1px solid #dddddd;
  height: 130px;
  resize: none;
  border-radius: 4px;
}
.dialogFooter {
  border-top: solid 1px #dddddd;
  height: 54px;
  text-align: right;
  padding-right: 20px;
  line-height: 54px;
  margin-top: 30px;
}
.dialogFooter button {
  width: 64px;
  height: 32px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}
.dialogFooter .primary {
  color: #fff;
  border: none;
}
</style>