import $ from 'jquery'
import { visitAnalysisUrl } from '@/config'
import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/down',
      name: 'Down',
      component: () => import('./views/Down.vue')
    },
    {
      path: '/support',
      name: 'Support',
      component: () => import('./views/Support.vue')
    },
    {
      path: '/aboutUs',
      name: 'AboutUs',
      component: () => import('./views/AboutUs.vue')
    },
    {
      path: '/tutorial',
      name: 'Tutorial',
      component: () => import('./views/tutorial/Tutorial.vue')
    },
  ]
})

router.afterEach((to) => {
  $.post(visitAnalysisUrl, { RequestUrl: to.path })
})

export default router
