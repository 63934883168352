<template>
  <div class="app">
    <ZzjHeader />
    <div class="content">
      <router-view />
    </div>
    <ZzjFooter />
    <ZzjSidebar />
  </div>
</template>

<script>
import ZzjHeader from './components/Header.vue'
import ZzjFooter from './components/Footer.vue'
import ZzjSidebar from './components/Sidebar.vue'

export default {
  name: 'App',
  components: {
    ZzjHeader,
    ZzjFooter,
    ZzjSidebar,
  }
}
</script>

<style scoped>
@font-face {
    font-family: 'YouSheBiaoTiHei';
    src: url('./assets/fonts/YouSheBiaoTiHei-2.ttf');
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.app .content {
  flex-grow: 1;
  position: relative;
}
.app .header,
.app .footer {
  flex-grow: 0;
}
</style>
