module.exports.ProductName = 'SPDKit'
module.exports.ProductVersion = 'v2.0.0'

if (process.env.NODE_ENV === 'development') {
  module.exports.downloadAnalysisUrl = 'http://uxp.spdkit.dev.spdio.com/api/v1/user/productdowns/download'
  module.exports.visitAnalysisUrl = 'http://uxp.spdkit.dev.spdio.com/api/v1/user/visits/visit'
  module.exports.downloadFileUrl = '/download/SPDKit.zip'
} else {
  module.exports.downloadAnalysisUrl = 'http://uxp.spdkit.com/api/v1/user/productdowns/download'
  module.exports.visitAnalysisUrl = 'http://uxp.spdkit.com/api/v1/user/visits/visit'
  module.exports.downloadFileUrl = 'https://cdn.spdspd.com/spider/kit/SPDKit.zip'
}
