<template>
  <button class="primary">
    <!-- <a @click="download" :href="downloadFileUrl"> 立即下载 </a> -->
    试用请联系微信客服
  </button>
</template>

<script>
import $ from "jquery";
import {
  downloadAnalysisUrl,
  downloadFileUrl,
  ProductName,
  ProductVersion,
} from "@/config";

export default {
  data() {
    return {
      downloadFileUrl,
    };
  },
  methods: {
    download() {
      $.post(downloadAnalysisUrl, { ProductName, ProductVersion });
    },
  },
};
</script>

<style scoped>
.primary a {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 50px;
}
.primary {
    width: initial !important;
    padding: 0 16px;
    background-color: #02d6dc;
    border-radius: 4px;
    color: #fff;
}
</style>