<template>
  <div class="main">
    <div class="bannerBg">
      <div class="bannerContent">
        <DownloadButton />
        <video
          src="/download/SPDKIT.mp4"
          muted
          controls="controls"
          autoplay="autoplay"
          loop="loop"
        ></video>
      </div>
    </div>
    <div class="showModel gray">
      <div class="container">
        <div>
          <img src="../assets/images/home_icon_p1.png" alt="" />
        </div>
        <div class="article">
          <span class="title">
            <span>BIM模型标准化</span>
          </span>
          <p class="content">
            软件结合《建筑信息模型应用统一标准》、《建筑工程设计信息模型制图标准》以及《建筑信息模型设计交付标准》，保障模型中需要共享的数据在建设过程各个阶段、各项任务和各相关方之间交换和应用
          </p>
        </div>
      </div>
    </div>
    <div class="showModel">
      <div class="container">
        <div class="article">
          <span class="title modeling">
            <span>AI助力建模效率</span>
          </span>
          <p class="content">
            在BIM构件或设备标准化的基础上，将AI技术融入到图纸识别和建模中，不断学习和提升识别率，将已经进行的项目实现二维图纸自动化生成三维模型，提高设计人员建模效率，减少企业成本
          </p>
        </div>
        <div>
          <img src="../assets/images/head_icon_p2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="showModel gray">
      <div class="container">
        <div>
          <img src="../assets/images/head_icon_p3.png" alt="" />
        </div>
        <div class="article">
          <span class="title">
            <span>三维云算量</span>
          </span>
          <p class="content">
            保证建筑信息模型的表达质量，提高信息传递效率，为三维模型云算量提供坚实的基础，建完模型即可实现清单算量、计价，实现BIM数据的价值
          </p>
        </div>
      </div>
    </div>
    <div class="showModel">
      <div class="container">
        <div class="article">
          <span class="title push">
            <span>智能命令推送</span>
          </span>
          <p class="content">
            结合用户操作和使用习惯，主动为用户实时推送相关功能和规范，让用户主动了解一些实用性的工具，降低学习成本和提升工作效率
          </p>
        </div>
        <div>
          <img src="../assets/images/head_icon_p4.png" alt="" />
        </div>
      </div>
    </div>
    <div class="blogrollBg">
      <div class="container">
        <p class="link">SPD生态</p>
        <p class="linkTxt">LINKS</p>
        <div class="blogroll">
          <a href="http://www.spdspd.com" target="_blank"></a>
          <a href="http://www.spd3d.com" target="_blank"></a>
          <a
            style="padding-top: 5px"
            href="http://www.spdcost.com"
            target="_blank"
          ></a>
          <a
            style="border: none; margin-right: 0"
            href="http://www.spdci.com"
            target="_blank"
          ></a>
          <a href="http://www.spdview.com" target="_blank"></a>
          <a href="http://www.spdqd.com" target="_blank"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadButton from '@/components/DownloadButton.vue'

export default {
  components: {
    DownloadButton
  }
}
</script>

<style scoped>
/* banner */
.bannerBg {
  background: #1d1d1d;
  width: 100%;
  height: 490px;
  display: flex;
  justify-content: center;
}
.bannerContent {
  min-width: 1280px;
  height: 100%;
  background: url("../assets/images/banner.png");
  position: relative;
}
.bannerContent button {
  position: absolute;
  bottom: 182px;
  left: 140px;
  width: 154px;
  height: 50px;
  font-size: 24px;
}
.bannerContent video {
  position: absolute;
  right: 153px;
  top: 99px;
}
/* showModel */
.showModel {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.showModel .container {
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 138px;
}
.gray {
  background-color: #f5f5f5;
}
.article {
  width: 425px;
  text-align: right;
}
.article .title {
  font-size: 32px;
  position: relative;
}
.article .title span {
  position: relative;
}
.article .title::before {
  content: "";
  position: absolute;
  width: 97px;
  height: 13px;
  background-color: #02d6dc;
  right: 0;
  bottom: 0;
}
.article .modeling::before {
  width: 130px;
}
.article .push::before {
  left: 0;
  width: 65px;
}
.article .content {
  color: #666;
  font-size: 16px;
  margin-top: 20px;
}
.gray .article {
  text-align: left;
}
/* blogroll */
.blogrollBg {
  width: 100%;
  height: 430px;
  background: url("../assets/images/bg_friendly link.png");
}
.blogrollBg .container {
  width: 1280px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  padding-left: 40px;
}
.blogrollBg p {
  font-size: 21px;
}
.blogrollBg .linkTxt {
  font-size: 21px;
  letter-spacing: 8.4px;
}
.blogrollBg .link {
  font-size: 44px;
  margin-top: 60px;
  margin-bottom: 5px;
}
.blogrollBg .blogroll {
  display: flex;
  flex-wrap: wrap;
  margin-top: 49px;
}
.blogroll a {
  width: 280px;
  display: block;
  border-right: 1px solid #ddd;
  margin-right: 39px;
  height: 50px;
  margin-bottom: 50px;
}
.blogroll a:first-child {
  background: url("../assets/images/friendlylink_logo_1.png") no-repeat;
}
.blogroll a:nth-child(2) {
  background: url("../assets/images/friendlylink_logo_2.png") no-repeat;
}
.blogroll a:nth-child(3) {
  background: url("../assets/images/friendlylink_logo_5.png") no-repeat;
}
.blogroll a:nth-child(4) {
  background: url("../assets/images/friendlylink_logo_4.png") no-repeat;
}
.blogroll a:nth-child(5) {
  background: url("../assets/images/friendlylink_logo_6.png") no-repeat;
}
.blogroll a:nth-child(6) {
  background: url("../assets/images/friendlylink_logo_7.png") no-repeat;
}
.blogroll a:first-child:hover {
  background: url("../assets/images/friendlylink_logo_1_ov.png") no-repeat;
}
.blogroll a:nth-child(2):hover {
  background: url("../assets/images/friendlylink_logo_2_ov.png") no-repeat;
}
.blogroll a:nth-child(3):hover {
  background: url("../assets/images/friendlylink_logo_5_ov.png") no-repeat;
}
.blogroll a:nth-child(4):hover {
  background: url("../assets/images/friendlylink_logo_4_ov.png") no-repeat;
}
.blogroll a:nth-child(5):hover {
  background: url("../assets/images/friendlylink_logo_6_ov.png") no-repeat;
}
.blogroll a:nth-child(6):hover {
  background: url("../assets/images/friendlylink_logo_7_ov.png") no-repeat;
}
.blogroll img {
  max-width: initial !important;
}
.blogroll a:last-child {
  border-right: none;
}
</style>