<template>
  <div>
    <div class="sidebarCon">
      <div class="bg">
        <a href="http://www.spdspd.com/">
          <img src="../assets/images/all_btn_home.png" alt="" />
        </a>
      </div>
      <div class="bg" @click="feedbackVisible = true">
        <img src="../assets/images/all_btn_fankui.png" />
      </div>
      <div class="bg phone">
        <span>400-088-4129</span>
        <img src="../assets/images/all_btn_phone.png" />
      </div>
      <div class="bg" @click="scrollTop">
        <img src="../assets/images/all_btn_top.png" />
      </div>
    </div>
    <ZzjFeedback v-show="feedbackVisible" @close="feedbackVisible = false" />
  </div>
</template>

<script>
import ZzjFeedback from "./Feedback.vue";

export default {
  name: "ZzjSidebar",
  components: {
    ZzjFeedback,
  },
  data() {
    return {
      feedbackVisible: false,
    };
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.sidebarCon {
  position: fixed;
  width: 48px;
  right: 27px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 99;
}
.sidebarCon .bg {
  width: 48px;
  height: 48px;
  background-color: rgba(2, 214, 220, 0.5);
  border-radius: 4px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.sidebarCon .bg:last-child {
  margin-bottom: 0;
}
.sidebarCon .bg::before {
  content: "返回SPD";
  color: #fff;
  display: none;
}
.sidebarCon .bg:nth-child(2)::before {
  content: "意见反馈";
}
.sidebarCon .bg:nth-child(3)::before {
  content: "联系电话";
}
.sidebarCon .bg:nth-child(4)::before {
  content: "返回顶部";
}
.sidebarCon .bg:hover {
  background-color: rgba(2, 214, 220, 1);
  padding: 7px 8px;
  line-height: 18px;
}
.sidebarCon .bg:hover img {
  display: none;
}
.sidebarCon .bg:hover::before {
  display: block;
}

.sidebarCon .bg:hover a {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}
.sidebarCon .phone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sidebarCon span {
  position: absolute;
  width: 153px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  left: -168px;
  font-size: 18px;
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
.sidebarCon .phone:hover span {
  opacity: 1;
  transition: 0.5s;
  line-height: 48px;
  transform: translate3d(0, 0, 0);
}
</style>